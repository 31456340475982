import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-49b06ae5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "c-postQuestion u-marginTopUI--large" }
const _hoisted_2 = { class: "c-postQuestion-title u-marginBottomUI--small" }
const _hoisted_3 = { class: "c-postQuestion-title" }
const _hoisted_4 = { class: "c-postQuestion-text" }
const _hoisted_5 = { class: "c-main" }
const _hoisted_6 = {
  key: 0,
  class: "u-marginBottomUI--xSmall"
}
const _hoisted_7 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!
  const _component_powered_by_okendo = _resolveComponent("powered-by-okendo")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_icon, {
        icon: "verified-tick",
        class: "c-postQuestion-image u-marginBottomUI--xxSmall"
      }),
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('Thank you!')), 1),
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('Your question has been submitted.')), 1),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t(`We'll send you an email once your question has been answered.`)), 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.moduleComponents, (component) => {
        return (_openBlock(), _createBlock(_resolveDynamicComponent(component.type), {
          moduleData: component,
          key: component.type,
          class: "c-module"
        }, null, 8, ["moduleData"]))
      }), 128)),
      (!_ctx.moduleComponents.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createElementVNode("button", {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.gotoStore())),
              "aria-label": _ctx.$t('Store Website'),
              class: "c-button"
            }, _toDisplayString(_ctx.$t('Return to {storeName}', { storeName: _ctx.storeName })), 9, _hoisted_7)
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_powered_by_okendo)
    ])
  ], 64))
}