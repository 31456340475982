
import { Options, Vue } from 'vue-class-component';

import store from '@/store';
import { postAnalytics } from '@/utils/api';
import { StyleSettings } from '@okendo/reviews-common';

import CustomButton from './components/CustomButton.vue';
import CustomImage from './components/CustomImage.vue';
import CustomText from './components/CustomText.vue';
import Icon from '@/shared-components/Icon.vue';
import PoweredByOkendo from '@/shared-components/PoweredByOkendo.vue';
import ShopMoreProducts from './components/ShopMoreProducts.vue';

@Options({
    components: {
        CustomButton,
        CustomImage,
        CustomText,
        Icon,
        PoweredByOkendo,
        ShopMoreProducts
    },
    store
})
export default class PostQuestion extends Vue {
    isFormSubmitted = false;

    mounted(): void {
        postAnalytics({ eventName: 'page-open' });
        window.scrollTo(0, 0);
    }

    get storeName(): string | undefined {
        const { storeName } = store.state.subscriber;
        return (storeName && storeName.length < 23) ? storeName : this.$t('Store');
    }

    get moduleComponents(): StyleSettings.RecorderPlus.QuestionModule[] {
        const settings = store.state.settings.settings;
        return settings.postQuestionModules || [];
    }

    gotoStore(): void {
        window.location.href = store.state.subscriber.storeUrl;
    }
}
